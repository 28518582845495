import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
// import { SettingsService, StartupService, TokenService } from '@core';
import { Router } from '@angular/router';
// import { ToastrService } from 'ngx-toastr';
import { catchError } from 'rxjs/operators';
import { OktaAppService } from '../../services/okta-app.service';
import { appConfigs } from '../../../core/config/app.configs';
import { SSOAuthService } from '../../services/sso-auth.service';
import { UserService } from '../../../core/user/user.service';
import { User } from '../../../core/user/user.types';
// import {user} from '../../../mock-api/common/user/data';
import { of } from 'rxjs';

@Component({
  template: '',
  providers: [OktaAppService],
})
export class CallbackComponent implements OnInit {
  oktaAuth = new OktaAuth({
    clientId: appConfigs.oidc.clientId,
    issuer: appConfigs.oidc.issuer,
    redirectUri: `${window.location.origin}${appConfigs.oidc.callback}`,
    tokenManager: {
      storage: 'sessionStorage',
    },
  });

  @Output() signInCompleted: EventEmitter<any> = new EventEmitter();
  private _authenticated: boolean = false;

  constructor(
    private okta: OktaAppService,
    private authService: SSOAuthService,
    // private settings: SettingsService,
    // private token: TokenService,
    // private startup: StartupService,
    private router: Router,
    // private toastr: ToastrService,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    //  ('Pass by here');
    this.handleAuthentication();
  }

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  get accessToken(): string {
    return localStorage.getItem('access_token') ?? '';
  }

  /**
   * Setter & getter for access token expiry
   */
  set accessTokenExpiry(token: string) {
    localStorage.setItem('access_token_exp', token);
  }

  get accessTokenExpiry(): string {
    return localStorage.getItem('access_token_exp') ?? '';
  }

  /**
   * Setter & getter for user details
   */
  set userDetails(token: string) {
    localStorage.setItem('user', token);
  }

  get userDetails(): string {
    return localStorage.getItem('user') ?? '';
  }

  async handleAuthentication(): Promise<any> {
    try {
      const storedUser = localStorage.getItem('user');
      const storedToken = localStorage.getItem('access_token');

      if (storedUser && storedToken) {
        ('User already logged in, redirecting...');
        const user = JSON.parse(storedUser);
        this._userService.user = user;
        this.redirectUser(user.role);
        return;
      }

      // If no stored user, proceed with authentication
      const tokens = await this.oktaAuth.token.parseFromUrl();
      const oktaData = { token: tokens };

      return (
        this.authService
          .oauth(oktaData)
          .pipe(catchError(async (err) => err))
          // .subscribe((data?: { token: string; exp: number, iat: number, user: any }) => {
          .subscribe((res?: any) => {
            //  (
            //     ' -------------------- data ---------------',
            //     res
            // );
            if (res) {
              const data = res;
              //  (data);
              this.accessToken = data.token;
              this.accessTokenExpiry = data.exp.toString();
              // this.accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.
              // eyJpYXQiOjE2MjAzNzEwMTgsImlzcyI6IlRyZW8iLCJleHAiOjE2MjA5NzU4MTh9.JGXiZnwT4qqdNqD1S2RbY75DUN4m-1uoYJo6REuQo9o';
              this._authenticated = true;
              const _user = {} as User;
              _user.id = data.user.id;
              _user.email = data.user.email;
              _user.name = `${data.user.firstname} ${data.user.lastname}`;
              _user.status = 'online';
              _user.role = data.user.functional_roles;
              this.userDetails = JSON.stringify(_user);
              //  ('_user', _user);
              this._userService.user = _user;
              //  ('_user', _user);
              // Handle redirection logic
              this.redirectUser(_user.role);
              // return of(data);
              // Set user info
              /*this.settings.setUser({
              id: data.user.id,
              name: `${data.user.firstname} ${data.user.lastname}`,
              email: data.user.email,
              avatar: '/assets/images/avatar.jpg', // do we need avatar?
            });*/
              // Set token info
              // this.token.set({ token: data.token, user: data.user });
              // Regain the initial data
              /*this.startup.load().then(() => {
              let url = this.token.referrer!.url || '/';
              if (url.includes('/auth')) {
                url = '/';
              }
              this.router.navigateByUrl(url);
            });*/
            }
          })
      );
    } catch (err) {
      localStorage.removeItem('currentUser');
      this.router.navigate(['/login']);
    }
  }

  /**
   * Redirect user based on role
   */
  redirectUser(roles: string[]): void {
    if (!roles || roles.length === 0) {
      this.router.navigate(['/sign-in']); // Fallback if no role found
      return;
    }

    const isProtocolAdmin = roles.includes('Protocol Admin');
    const isProtocolUser = roles.includes('Protocol User');

    if (isProtocolUser && !isProtocolAdmin) {
      this.router.navigate(['/users/my-family-members']);
    } else {
      this.router.navigate(['/dashboards/analytics']);
    }
  }
}
