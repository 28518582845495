// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    oktaCredentials: {
        // AFREXIM ENV
        // clientId: `0oa12mqu7abBddRry0h8`,
        // issuer: `https://dev-886152.oktapreview.com/oauth2/default`,
        // callback: '/implicit/callback',
        // redirectUri: 'http://localhost:4200/implicit/callback',
        // scopes: ['openid', 'profile', 'email'],

        // REVENT ENV
        clientId: `0oai70hrhwUA95Pkx5d7`,
        issuer: `https://dev-76988434.okta.com/oauth2/default`,
        callback: '/implicit/callback',
        redirectUri: 'https://protocol-service-web.reventacademy.com/implicit/callback',
        scopes: ['openid', 'profile', 'email'],

        // LOCAL
        // clientId: `0oai70hrhwUA95Pkx5d7`,
        // issuer: `https://dev-76988434.okta.com/oauth2/default`,
        // callback: '/implicit/callback',
        // redirectUri: 'http://localhost:4200/implicit/callback',
        // scopes: ['openid', 'profile', 'email'],
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
