import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';

import { CallbackComponent } from './shared/components/callback/callback.component';
import { NotFoundComponent } from './shared/errors-layout/not-found/not-found.component';
import { RoleBasedRedirectGuard } from './core/auth/guards/role-based-guard.guard';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
  { path: '', pathMatch: 'full', redirectTo: '/dashboards/analytics' },
  {
    path: 'signed-in-redirect',
    pathMatch: 'full',
    redirectTo: '/dashboards/analytics',
  },

  // Auth routes for guests
  {
    path: '',
    canActivate: [NoAuthGuard],
    canActivateChild: [NoAuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'implicit/callback',
        component: CallbackComponent,
        data: { title: 'Callback', titleI18n: 'callback' },
      },
      {
        path: 'sign-in',
        loadChildren: () =>
          import('app/modules/auth/sign-in/sign-in.module').then(
            (m) => m.AuthSignInModule
          ),
      },
    ],
  },

  //Guarded routes based on user role
  {
    path: '',
    canActivate: [AuthGuard, RoleBasedRedirectGuard], // Apply the new guard
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: 'users',
        children: [
          {
            path: '',
            loadChildren: () =>
              import(
                'app/modules/protocol-management/user-mgmt/user-mgmt.module'
              ).then((m) => m.UserMgmtModule),
          },
        ],
      },
    ],
  },
  // Auth routes for authenticated users
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
    children: [
      {
        path: 'sign-out',
        loadChildren: () =>
          import('app/modules/auth/sign-out/sign-out.module').then(
            (m) => m.AuthSignOutModule
          ),
      },
      {
        path: 'unlock-session',
        loadChildren: () =>
          import('app/modules/auth/unlock-session/unlock-session.module').then(
            (m) => m.AuthUnlockSessionModule
          ),
      },
    ],
  },

  // Landing routes
  {
    path: '',
    component: LayoutComponent,
    data: {
      layout: 'empty',
    },
  },

  // Admin routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: '404-not-found',
        pathMatch: 'full',
        component: NotFoundComponent,
      },
      {
        path: 'dashboards',
        children: [
          {
            path: 'accounts',
            loadChildren: () =>
              import(
                'app/modules/protocol-management/dashboard/account/account.module'
              ).then((m) => m.AccountDashboardModule),
          },
          {
            path: 'analytics',
            canActivate: [RoleBasedRedirectGuard],
            loadChildren: () =>
              import(
                'app/modules/protocol-management/dashboard/analytics/analytics.module'
              ).then((m) => m.AnalyticsModule),
          },
        ],
      },
    ],
  },

  // Protocol Mgmt routes
  {
    path: '',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [
      {
        path: 'users',
        children: [
          {
            path: '',
            loadChildren: () =>
              import(
                'app/modules/protocol-management/user-mgmt/user-mgmt.module'
              ).then((m) => m.UserMgmtModule),
          },
        ],
      },
      {
        path: 'my',
        children: [
          {
            path: 'assets',
            loadChildren: () =>
              import(
                'app/modules/protocol-management/assets/assets.module'
              ).then((m) => m.AssetsModule),
          },
          {
            path: 'requests',
            loadChildren: () =>
              import(
                'app/modules/protocol-management/requests/requests.module'
              ).then((m) => m.RequestsModule),
          },
          // {
          //     path: 'staff-members',
          //     loadChildren: () => import('app/modules/my/staff-members/staff-members.module').then(m => m.StaffMembersModule)
          // }
        ],
      },
      {
        path: 'all',
        children: [
          {
            path: 'assets',
            loadChildren: () =>
              import(
                'app/modules/protocol-management/assets/assets.module'
              ).then((m) => m.AssetsModule),
          },
          {
            path: 'requests',
            loadChildren: () =>
              import(
                'app/modules/protocol-management/requests/requests.module'
              ).then((m) => m.RequestsModule),
          },
        ],
      },
    ],
  },
  {
    path: '**',
    pathMatch: 'full',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    component: LayoutComponent,
    resolve: {
      initialData: initialDataResolver,
    },
    children: [{ path: '', component: NotFoundComponent }],
  },
];
