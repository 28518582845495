/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'Dashboards',
        subtitle: 'Unique dashboard designs',
        type: 'group',
        role: ['super_admin'],
        // icon: 'heroicons_outline:home',
        children: [
            /*{
                id   : 'dashboards.accounts',
                title: 'Accounts',
                type : 'basic',
                icon : 'heroicons_outline:cash',
                link : '/dashboards/accounts'
            },*/
            {
                id: 'dashboards.analytics',
                title: 'Analytics',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/analytics'
            },
            /*{
                id   : 'dashboards.crypto',
                title: 'Crypto',
                type : 'basic',
                icon : 'heroicons_outline:currency-dollar',
                link : '/dashboards/crypto'
            }*/
        ]
    },
    {
        id: 'portal',
        title: 'My Portal',
        subtitle: 'Passports, Laissez-Passer, ...',
        type: 'group',
        // icon: 'heroicons_outline:home',
        role: ['staff', 'super_admin'],
        children: [
            {
                id: 'users.family-members',
                title: 'My Family Members',
                link: '/users/my-family-members',
                type: 'basic',
            },
            {
                id: 'portal.assets',
                title: 'My Assets',
                subtitle: 'Passports, Laissez-Passer, ...',
                type: 'collapsable',
                // icon: 'heroicons_outline:home',
                children: [
                    {
                        id: 'portal.assets.visas',
                        title: 'My Visas',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/my/assets/visas'
                    },
                    {
                        id: 'portal.assets.ids',
                        title: 'My Passports',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/my/assets/passports'
                    },
                    {
                        id: 'portal.assets.vehicles',
                        title: 'My Vehicles',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/my/assets/vehicles'
                    },
                    {
                        id: 'portal.assets.my.idCards',
                        title: 'My Diplomatic ID Cards',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/my/assets/id-cards'
                    }
                ]
            },
            {
                id: 'portal.requests',
                title: 'My Requests',
                subtitle: 'Passports, Laissez-Passer, ...',
                type: 'collapsable',
                // icon: 'heroicons_outline:home',
                children: [
                    {
                        id: 'portal.assets.my-entry-visa',
                        title: 'My Entry Visa Requests',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/my/requests/visas/entry-visa-requests'
                    },
                    {
                        id: 'portal.assets.my-residential-visa',
                        title: 'My Residential Visa Requests',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/my/requests/visas/residential-visa-requests'
                    },
                    {
                        id: 'staff_assets.my-vehicle',
                        title: 'My Vehicle Requests',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/my/requests/vehicles'
                    },
                    {
                        id: 'portal.assets.my.idCards',
                        title: 'My Diplomatic ID Card Requests',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/my/requests/id-cards'
                    },
                    {
                        id: 'portal.assets.my.otherLegalRequests',
                        title: 'My Other Statutory Requests',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/my/requests/other-legal-requests'
                    }
                ]
            },
        ]
    },
    {
        id: 'admin',
        title: 'Administration',
        subtitle: 'Passports, Laissez-Passer, ...',
        type: 'group',
        // icon: 'heroicons_outline:home',
        role: ['super_admin', 'prot_admin'],
        children: [
            {
                id: 'admin.users',
                title: 'Users',
                subtitle: 'Staff Members,Family Members ...',
                type: 'collapsable',
                // icon: 'heroicons_outline:home',
                children: [
                    {
                        id: 'all_users.all-staff-members',
                        title: 'Staff Members',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/users/staff-members'
                    },
                    {
                        id: 'all_users.all-family-members',
                        title: 'Family Members',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/users/all-family-members'
                    },

                ]
            },
            {
                id: 'admin.assets',
                title: 'Assets',
                subtitle: 'Passports, Laissez-Passer, ...',
                type: 'collapsable',
                // icon: 'heroicons_outline:home',
                children: [
                    {
                        id: 'my_assets.all-visas',
                        title: 'All Visas',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/all/assets/visas'
                    },
                    {
                        id: 'my_assets.ids',
                        title: 'All Passports',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/all/assets/passports'
                    },
                    {
                        id: 'portal.assets.vehicles',
                        title: 'All Vehicles',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/all/assets/vehicles'
                    },
                    {
                        id: 'portal.assets.idCards',
                        title: 'All Diplomatic ID Cards',
                        type: 'basic',
                        // icon: 'heroicons_outline:cash',
                        link: '/all/assets/id-cards'
                    }

                ]
            },
            {
                id: 'admin.requests',
                title: 'Requests',
                subtitle: 'Passports, Laissez-Passer, ...',
                type: 'collapsable',
                role: ['admin'],
                // icon: 'heroicons_outline:home',
                children: [
                    {
                        id: 'my_assets.all-visas',
                        title: 'All Entry Visa Requests',
                        type: 'basic',
                        icon: 'heroicons_outline:cash',
                        link: '/all/requests/visas/all-entry-visa-requests'
                    },
                    {
                        id: 'my_assets.all-visas',
                        title: 'All Residential Visa Requests',
                        type: 'basic',
                        icon: 'heroicons_outline:cash',
                        link: '/all/requests/visas/all-residential-visa-requests'
                    },
                    {
                        id: 'admin.requests.family',
                        title: 'All Vehicle Requests',
                        type: 'basic',
                        icon: 'heroicons_outline:cash',
                        link: '/all/requests/vehicles'
                    },
                    {
                        id: 'portal.assets.all.idCards',
                        title: 'All Diplomatic ID Card Requests',
                        type: 'basic',
                        icon: 'heroicons_outline:cash',
                        link: '/all/requests/id-cards'
                    },
                    {
                        id: 'portal.assets.my.otherLegalRequests',
                        title: 'All Other Statutory Requests',
                        type: 'basic',
                        icon: 'heroicons_outline:cash',
                        link: '/all/requests/other-legal-requests/all-other-legal-requests'
                    }
                ]
            },

        ]
    },
];
export const compactNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        tooltip : 'Dashboards',
        type    : 'aside',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        tooltip : 'Apps',
        type    : 'aside',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        tooltip : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'UI',
        tooltip : 'UI',
        type    : 'aside',
        icon    : 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Navigation',
        tooltip : 'Navigation',
        type    : 'aside',
        icon    : 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const futuristicNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'DASHBOARDS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'APPS',
        type    : 'group',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id   : 'others',
        title: 'OTHERS',
        type : 'group',
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'aside',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'User Interface',
        type    : 'aside',
        icon    : 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Navigation Features',
        type    : 'aside',
        icon    : 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
export const horizontalNavigation: FuseNavigationItem[] = [
    {
        id      : 'dashboards',
        title   : 'Dashboards',
        type    : 'group',
        icon    : 'heroicons_outline:home',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'apps',
        title   : 'Apps',
        type    : 'group',
        icon    : 'heroicons_outline:qrcode',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'pages',
        title   : 'Pages',
        type    : 'group',
        icon    : 'heroicons_outline:document-duplicate',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'user-interface',
        title   : 'UI',
        type    : 'group',
        icon    : 'heroicons_outline:rectangle-stack',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
    {
        id      : 'navigation-features',
        title   : 'Misc',
        type    : 'group',
        icon    : 'heroicons_outline:bars-3',
        children: [], // This will be filled from defaultNavigation so we don't have to manage multiple sets of the same navigation
    },
];
