// import { Layout } from 'app/layout/layout.types';
import { environment } from '../../../environments/environment';

// Types
export type Scheme = 'auto' | 'dark' | 'light';
export type Theme = 'default' | string;

/**
 * AppConfig interface. Update this interface to strictly type your config
 * object.
 */
export interface AppConfig {
    // layout: Layout;
    scheme: Scheme;
    theme: Theme;
    routes: any;
    endpoints: any;
    votesLimit: number;
    topHeroesLimit: number;
    snackBarDuration: number;
    repositoryURL: string;
    oidc: any;
    frontendUrl: string;
}

/**
 * Default configuration for the entire application. This object is used by
 * TreoConfigService to set the default configuration.
 *
 * If you need to store global configuration for your app, you can use this
 * object to set the defaults. To access, update and reset the config, use
 * TreoConfigService and its methods.
 */
export const appConfigs: AppConfig = {
    // layout: 'enterprise',
    scheme: 'light',
    theme: 'default',
    routes: {
        contacts: 'contacts',
        verification: 'verification',
        login: 'login',
        callback: 'implicit/callback',
        protected: 'protected',
        error404: '404',
    },
    endpoints: {
        contacts: '/v1/contact',
        countries: '/v1/country',
        auth: '/v1/auth',
    },
    oidc: {
        // clientId: `0oa12mqu7abBddRry0h8`,
        // issuer: `https://dev-886152.oktapreview.com/oauth2/default`,
        // callback: '/implicit/callback',
        // redirectUri: 'http://localhost:4200/implicit/callback',
        // scopes: ['openid', 'profile', 'email'],

        clientId: environment.oktaCredentials.clientId,
        issuer: environment.oktaCredentials.issuer,
        callback: environment.oktaCredentials.callback,
        redirectUri: environment.oktaCredentials.redirectUri,
        scopes: ['openid', 'profile', 'email'],
    },
    frontendUrl: `${window.location.origin}`,
    // oidc: {
    //     clientId: `0oalg0x99cQsqlZyz1t7`,
    //     issuer: `https://afreximbank.okta.com/oauth2/default`,
    //     callback: '/implicit/callback',
    //     redirectUri: 'https://prot-ms-uat.afreximbank.net/implicit/callback',
    //     scopes: ['openid', 'profile', 'email'],
    // },
    // frontendUrl: 'https://prot-ms-uat.afreximbank.net',
    votesLimit: 3,
    topHeroesLimit: 4,
    snackBarDuration: 3000,
    repositoryURL: 'https://github.com/lidibe/.git',
};
