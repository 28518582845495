import { Injectable, EventEmitter, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

import { AccessToken, IDToken, OktaAuth, Token } from '@okta/okta-auth-js';
// import { SettingsService, StartupService, TokenService } from '@core';
import { appConfigs } from '../../core/config/app.configs';
import { SSOAuthService } from './sso-auth.service';


@Injectable()
export class OktaAppService {

  private user = new Subject<any>();

  oktaAuth = new OktaAuth({
    clientId: appConfigs.oidc.clientId,
    issuer: appConfigs.oidc.issuer,
    redirectUri: `${window.location.origin}${appConfigs.oidc.callback}`,
    tokenManager: {
      storage: 'sessionStorage',
    },
  });

  @Output() signInCompleted: EventEmitter<any> = new EventEmitter();
  @Input() authenticated = false;

  userStorage: any;

  constructor(private router: Router,
    private authService: SSOAuthService,
    // private settings: SettingsService,
    // private token: TokenService,
    // private startup: StartupService,
  ) {
  }

  isAuthenticated(): any {
    this.userStorage = localStorage.getItem('currentUser');
    return !!this.userStorage;
  }

  login(): void { 
    // Launches the login redirect.
    try {
      this.oktaAuth.token.getWithRedirect({
        responseType: ['id_token', 'token'],
        scopes: ['openid', 'email', 'profile'],
      });
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Returns the current idToken in the tokenManager.
   */
  async getIdToken(): Promise<AccessToken | IDToken> {
    try {
      // @ts-ignore
      return await this.oktaAuth.tokenManager.get('idToken');

    } catch (err) {
      // The user no longer has an existing SSO session in the browser.
      // (OIDC error `login_required`)
      // Ask the user to authenticate again.
      return undefined;
    }
  }

  async handleAuthentication(): Promise<void> {

    try {
      const tokens = await this.oktaAuth.token.parseFromUrl();

      const data = { token: tokens };

      this.authService.oauth(data).subscribe((result: any) => {
        this.authenticated = true;
        this.signInCompleted.emit(this.authenticated);
        this.user = result.data;

        if (result.data) {
          const user = {
            id: result.data.user.id,
            name: `${result.data.user.firstname} ${result.data.user.lastname}`,
            email: result.data.user.email,
            roles: result.data.user.role,
            avatar: '/assets/images/avatar.jpg', // do we need avatar?
          };
        }
      });
    } catch (err) {
      console.log('err', err);
      localStorage.removeItem('currentUser');
      this.router.navigate(['/login']);
    }
  }

  async logout(): Promise<void> {
    this.oktaAuth.tokenManager.clear();
    await this.oktaAuth.signOut();
    this.authenticated = false;
    this.signInCompleted.emit(this.authenticated);
    this.router.navigateByUrl('/login');
  }
}
