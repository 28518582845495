<div class="flex flex-col flex-auto min-w-0">
    <div class=" sm:items-center sm:justify-between p-6 sm:py-8 sm:px-10 border-b bg-card dark:bg-transparent">

        <head>
            <link rel="preconnect" href="https://fonts.googleapis.com">
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
            <link href="https://fonts.googleapis.com/css2?family=Poppins&family=Titillium+Web:wght@400&display=swap"
                rel="stylesheet">
        </head>
        <div class=" inset-0">
            <!-- Main -->
            <div class=" flex flex-col flex-auto items-center justify-center"
                style="font-family: 'Titillium Web', sans-serif;">
                <img class="fuck" src="assets/images/Error404.png" alt="404 illustration">
                <h4 class="text-xl md:text-3xl mt-2 tracking-tight leading-tight md:leading-none text-center">Page not
                    found
                </h4>
                <div class="mt-2 text-lg md:text-xl font-medium tracking-tight text-center text-secondary">The page you
                    are
                    trying to reach is not found</div>
                <a class="mt-12 text-primary underline" [routerLink]="['/']">
                    Back to Dashboard
                </a>
            </div>
        </div>
    </div>
</div>