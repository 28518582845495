import { Injectable } from '@angular/core';
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoleBasedRedirectGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    if (user && user.role) {
      const roles: string[] = user.role;

      // Check if user only has "Protocol User"
      const isOnlyProtocolUser =
        roles.includes('Protocol User') && !roles.includes('Protocol Admin');

      // Only redirect if the user is trying to access `/dashboards/analytics`
      if (isOnlyProtocolUser && state.url.includes('/dashboards/analytics')) {
        this.router.navigate(['/users/my-family-members']);
        return false; // Prevent navigation to the original route
      }
    }

    return true; // Allow navigation for admins and other roles
  }
}
